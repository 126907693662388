.how-to-buy-container {
  position: sticky;
  top: 150px;
}

.how-to-buy-major-detail {
  display: flex;
  align-items: center;
  gap: 40px;
}

.how-to-buy-step-detail {
  margin-left: 50px;
}

.how-to-buy-major-detail > img,
.how-to-buy-step-detail > img {
  width: 40px;
}

.how-to-buy-major-detail > h4 {
  color: #4cff27;
  font-size: 30px;
  font-family: "Sora", sans-serif;
  margin-bottom: 20px;
}

.how-to-buy-main-header > h4 {
  color: #4cff27;
  font-size: 18px;
  font-family: "Sora", sans-serif;
}

.how-to-buy-main-header > p {
  width: 450px;
  margin-top: 10px;
  color: #fff;
  font-family: "Darker Grotesque";
  font-weight: 700;
  font-size: 15px;
}

.ant-select-selector {
  font-family: "Palanquin Dark" !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  background: #1111 !important;
  color: #fff !important;
  border: 1px solid #1111 !important;
}

.ant-select-selection-item {
  color: #fff !important;
}

.ant-select-item-option-selected {
  background: #000 !important;
  color: #fff !important;
}

.ant-select-focused:hover {
  border: none !important;
}

.ant-select-item-option-active {
  background: #000 !important;
  color: #fff !important;
}

.ant-select-dropdown {
  background: #000 !important;
}

.ant-select-item-option {
  color: #fff !important;
}

.ant-select-arrow {
  color: #fff !important;
}

.how-to-buy-step-detail {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

@media (max-width: 700px) {
  .how-to-buy-main-header > p {
    width: 340px;
  }
  .how-to-buy-step-detail {
    margin-left: 10px;
    margin-bottom: 30px;
  }
  .how-to-buy-major-detail > h4 {
    font-size: 20px;
  }
  .how-to-buy-major-detail > h4 {
    font-size: 24px;
  }
  .how-to-buy-major-detail {
    gap: 20px;
  }
  .how-to-buy-step-detail {
    gap: 10px;
  }
  .how-to-buy-major-detail > img,
  .how-to-buy-step-detail > img {
    width: 30px;
  }
}
