.header-container {
  background: #0c0c11;
  padding: 10px 30px;
}

.sticky-header {
  background: #0c0c11;
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 10px 30px;
  top: 100px;
}

.marquee-container {
  display: flex;
  gap: 20px;
}

.header-socials {
  /* width: 30%; */
}

.header-details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-market-cap > h5 span,
.header-volume > h5 span {
  font-size: 19px;
  font-family: "Darker Grotesque", sans-serif;
}

.header-market-cap > h5,
.header-volume > h5 {
  color: #fff;
  font-family: "Darker Grotesque", sans-serif;
  font-size: 19px;
}

.header-market-cap > h5 span,
.header-volume > h5 span {
  color: #4cff27;
}

.header-socials-icon {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 600px) {
  .header-market-cap > h5 span,
  .header-volume > h5 span {
    font-size: 14px;
  }
  .header-market-cap > h5,
  .header-volume > h5 {
    font-size: 12px;
  }
  .header-socials-icon > a > img {
    width: 18px;
  }
  .header-socials-icon {
    gap: 10px;
  }
  .sticky-header {
    width: 100%;
  }
}
