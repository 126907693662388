.giganomic-container {
  padding: 20px;
  background: url("../../Assets/images/gigatonic-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.giganomics-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.giganomic-text > h4 {
  color: #fff;
  font-size: 30px;
}

.giganomic-text > img {
  position: relative;
}

.giganomic-text > h4 {
  position: absolute;
  z-index: 1;
  margin-left: 105px;
  font-size: 30px;
  font-family: "Sora", sans-serif;
  margin-top: 18px;
}

.giganomics-on-eth,
.giganomics-talik {
  background: url("../../Assets/images/gigatalik-on-eth-vector.svg");
  background-size: contain;
  width: 500px;
  height: 400px;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding: 50px;
}

.giganomic-main-details {
  align-items: center;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.giganomics-on-eth,
.giganomics-talik {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.giganomics-on-eth > h4,
.giganomics-talik > h4 {
  color: #4cff27;
  font-family: "Sora", sans-serif;
  font-size: 30px;
  margin-bottom: 30px;
}

.giganomics-on-eth > h5,
.giganomics-talik > h5 {
  color: #fff;
  font-family: "Sora", sans-serif;
  font-size: 20px;
  margin-bottom: 30px;
}

.giganomics-on-eth > h5.colored,
.giganomics-talik > h5.colored {
  color: #4cff27;
  text-align: center;
}

.giganomics-talik > p {
  color: #fff;
  font-family: "Sora", sans-serif;
  font-weight: 700;
}

.giganomics-talik > p > span {
  color: #4cff27;
  font-size: 20px;
}

.giganomic-button {
  position: absolute;
  bottom: -15px;
}

.giganomic-button > button {
  color: #000;
  background: #4cff27;
  border: none;
  padding: 12px 30px;
  clip-path: polygon(
    20px 0%,
    100% 0%,
    100% calc(100% - 10px),
    calc(100% - 20px) 100%,
    0% 100%,
    0% 10px
  );
  font-size: 16px;
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 600;
}

.audited-text > h4 {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: "Sora", sans-serif;
}

.audited-text div > img {
  width: 150px;
}

@media (max-width: 1400px) {
  .giganomic-main-details {
    flex-direction: column;
    gap: 30px;
    margin-top: 0px;
  }
}

@media (max-width: 600px) {
  .giganomic-container {
    padding: 10px;
  }
  .giganomics-details {
    margin-top: 20px;
  }
  .giganomics-on-eth,
  .giganomics-talik {
    width: 340px;
    height: 330px;
    padding: 10px;
  }
  .giganomics-talik {
    width: 350px;
    height: 300px;
    padding: 10px;
  }
  .giganomics-on-eth > h5,
  .giganomics-talik > h5 {
    color: #fff;
    font-family: "Sora", sans-serif;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .audited-text {
    margin-top: 10px;
  }
  .giganomics-on-eth > h4,
  .giganomics-talik > h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .giganomics-talik > p {
    font-size: 10px;
    font-weight: 600;
  }
  .giganomic-text > img {
    width: 230px;
  }
  .giganomic-text > h4 {
    margin-left: 60px;
    font-size: 20px;
    margin-top: 12px;
  }
  .giganomics-details {
    margin-top: 20px;
  }
  .giganomics-talik > p > span {
    color: #4cff27;
    font-size: 15px;
  }
}
