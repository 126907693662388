.footer-container {
  padding: 20px;
}

.footer-navigation {
  display: flex;
  gap: 80px;
  align-items: center;
  padding: 20px;
}

.nav-list ul {
  display: flex;
  gap: 40px;
}

.nav-list ul li {
  color: #fff;
  list-style: none;
  font-family: "Palanquin Dark", sans-serif;
  font-size: 17px;
}

.nav-list ul li:hover {
  cursor: pointer;
}

.footer-navigation > div > h5 {
  color: #fff;
  font-family: "Sora", sans-serif;
  font-size: 19px;
  margin-bottom: 10px;
}

.footer-container > p {
  text-align: center;
  color: #8d8d8d;
  font-family: "Palanquin Dark", sans-serif;
}

.footer-vector {
  width: 100%;
  margin-top: 50px;
}

@media (max-width: 1200px) {
  .nav-list ul {
    flex-wrap: wrap;
    gap: 20px;
  }
  .footer-navigation {
    flex-direction: column;
  }
  .footer-vector {
   margin: auto;
  }
}
