.ens-domains-container {
  padding: 20px;
}

.ens-domains-items {
  display: flex !important;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.ant-carousel .slick-dots {
  bottom: -20px !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #4cff27 !important;
}

.ens-domains-details {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ens-domains-details > h4 {
  color: #fff;
  margin-bottom: 30px;
  font-family: "Palanquin Dark", sans-serif;
  font-size: 30px;
  font-weight: 600;
}

.ens-domains-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 40px;
}

.ens-domains-wrapper .ant-carousel .slick-next,
.ens-domains-wrapper .ant-carousel .slick-prev {
  background: #858986 !important;
  height: 45px !important;
  width: 45px !important;
  border-radius: 50%;
  opacity: 1 !important;
  border: 2px solid #fff;
  top: auto !important;
  bottom: -100px !important;
}

.ens-domains-wrapper .ant-carousel .slick-next::after,
.ens-domains-wrapper .ant-carousel .slick-prev::after {
  top: 15px !important;
  left: 15px !important;
  width: 12px !important;
  height: 12px !important;
}

.ens-domains-wrapper .ant-carousel .slick-next {
  bottom: 0px;
  margin-right: 10%;
}

.ens-domains-wrapper .ant-carousel .slick-prev {
  bottom: 0px;
  margin-left: 80%;
}

@media (max-width: 1200px) {
  .ens-domains-item:nth-child(n + 3) {
    display: none;
  }
  .ens-domains-item > img {
    width: 140px;
  }
}

@media (max-width: 600px) {
  .ens-domains-item > img {
    width: 140px;
  }
  .ens-domains-details > h4 {
    font-size: 25px;
  }
  .ens-domains-wrapper .ant-carousel .slick-next,
.ens-domains-wrapper .ant-carousel .slick-prev{
  display: none !important;
}
}
