.community-container {
  padding: 20px;
}

.community-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.community-details {
  display: flex;
  gap: 50px;
  justify-content: space-around;
  align-items: center;
}

.community-main-text > div {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.community-main-text > div > img {
  position: relative;
  width: 450px;
}

.community-main-text > div h5 {
  position: absolute;
  width: 320px;
  font-family: "Sora", sans-serif;
  font-size: 17px;
  font-weight: 400;
  z-index: 1;
  color: #fff;
}

.community-main-text > div h5 > span {
  font-weight: 700;
}

.community-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.community-btn > img {
  width: 30px;
}

.community-illustration > img {
  width: 440px;
}

@media (max-width: 1200px) {
  .community-illustration > img {
    width: 300px;
  }
  .community-details {
    flex-direction: column;
  }
  .community-main-text > div > img {
    width: 340px;
  }
  .community-main-text > div h5 {
    font-size: 14px;
    margin-left: 0px;
    width: 260px;
  }
}
