.mvp-container {
  padding: 20px;
  margin-top: 80px;
}

.mvp-wrapper {
  padding: 25px;
  width: 92%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 4px solid #4cff27;
  border-radius: 20px;
  background: url("../../Assets/images/mvp-main-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.mvp-wrapper > h4 {
  color: #fff;
  font-family: "Palanquin Dark", sans-serif;
  font-size: 30px;
  font-weight: 700;
}

.mvp-wrapper > p {
  margin-top: 20px;
  width: 70%;
  color: #fff;
  font-family: "Palanquin Dark", sans-serif;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
}

.mvp-wrapper > p > span {
  color: #4cff27;
  font-size: 16px;
}

.mvp-wrapper .ant-carousel {
  width: 100% !important;
}

.mvp-item {
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mvp-item > img {
  width: 110px;
}

.mvp-items {
  display: flex !important;
  margin-top: 30px;
  justify-content: center !important;
  align-items: center !important;
  gap: 30px;
}

.mvp-item > p {
  text-align: center;
  color: #fff;
  font-family: "Sora", sans-serif;
  font-weight: 600;
}

.mvp-item p > span {
  color: #4cff27;
}

/* Flip Card Implementation */

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */

.flip-card {
  width: 250px;
  height: 220px;
}

.mvp-wrapper .ant-carousel .slick-next,
.mvp-wrapper .ant-carousel .slick-prev {
  background: #858986 !important;
  height: 45px !important;
  width: 45px !important;
  border-radius: 50%;
  opacity: 1 !important;
  border: 2px solid #fff;
}

.mvp-wrapper .ant-carousel .slick-next::after,
.mvp-wrapper .ant-carousel .slick-prev::after {
  top: 15px !important;
  left: 15px !important;
  width: 12px !important;
  height: 12px !important;
}

.mvp-wrapper .ant-carousel .slick-next {
  right: -50px;
}

.mvp-wrapper .ant-carousel .slick-prev {
  left: -50px;
}

.mvp-wrapper .ant-carousel .slick-next::after {
  left: 12px !important;
}

/* This container is needed to position the front and back side */

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */

.flip-card-front {
  /* background-color: #bbb;
  color: black; */
}

/* Style the back side */

.flip-card-back {
  transform: rotateY(180deg);
}

.flip-card-front:hover,
.flip-card-back:hover {
  cursor: pointer;
}

.flip-card-back > img {
  width: 150px;
  height: 150px;
}
.mvp-modal > div>div{
  background: transparent !important;
}
.ant-modal-content{
  width: 340px !important;
  margin: auto !important;
}

@media (max-width: 1200px) {
  .mvp-wrapper .ant-carousel .slick-next,
  .mvp-wrapper .ant-carousel .slick-prev {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .flip-card:nth-child(n + 4) {
    display: none;
  }
  .mvp-item {
    height: 110px;
  }
  .mvp-item > img {
    width: 100px;
  }
  .flip-card-back > img {
    width: 100px;
    height: 120px;
  }
  .flip-card {
    width: 110px;
    height: 120px;
  }
  .mvp-wrapper > p {
    width: 100%;
    margin-top: 5px;
    font-size: 13px;
  }
  .mvp-wrapper {
    width: 95%;
    border: 0px;
    padding: 15px;
  }
  .flip-card-back > img {
    display: none;
  }
  .flip-card:hover .flip-card-inner {
    transform: rotateY(0deg);
  }
  .mvp-item > p {
    margin-left: 0px;
  }
}
