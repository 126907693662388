.buy-container {
  padding: 20px;
}

.buy-details {
  display: flex;
  justify-content: center;
  gap: 80px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.buy-now-details {
  width: 400px;
}

.buy-illustrations {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.buy-now-details > h3 {
  text-align: center;
  background: linear-gradient(
    330deg,
    hsl(110deg 100% 58%) 0%,
    hsl(109deg 100% 64%) 39%,
    hsl(107deg 100% 68%) 58%,
    hsl(106deg 100% 72%) 69%,
    hsl(105deg 100% 76%) 77%,
    hsl(104deg 100% 80%) 83%,
    hsl(103deg 100% 83%) 87%,
    hsl(102deg 100% 86%) 91%,
    hsl(101deg 100% 90%) 94%,
    hsl(101deg 100% 93%) 96%,
    hsl(100deg 100% 97%) 98%,
    hsl(0deg 0% 100%) 100%
  );
  font-size: 35px;
  font-family: "Sora", sans-serif;
  font-weight: 900;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;
}

.multi-chain-illustration img {
  width: 450px;
}

.buy-illustrations > img {
  width: 150px;
}

.buy-now-details > p {
  color: #fff;
  font-size: 15px;
  font-family: "Sora", sans-serif;
  text-align: center;
  font-weight: 400;
  margin-bottom: 30px;
}

.Uniswap iframe {
  width: 450px;
  height: 600px;
  border-radius: 10px;
  border: none;
}

.multi-chain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px;
  margin-bottom: 100px;
  margin-top: 50px;
}

.multi-chain-text > h4 {
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 40px;
}

.multi-chain-text > h4 > span {
  color: #4cff27;
}

.multi-chain-first-flex,
.multi-chain-second-flex {
  display: flex;
  gap: 20px;
}

.multi-chain-first-flex {
  margin-bottom: 10px;
}

.multi-chain-first-flex > img,
.multi-chain-second-flex > img {
  width: 150px;
}

@media (max-width: 700px) {
  .multi-chain-text > h4 {
    font-size: 26px;
  }
  .hkRZjR {
    width: 340px !important;
  }
  
.Uniswap iframe {
  width: 340px;
}
  .fITZhz {
    width: 340px !important;
  }
  .buy-now-details {
    width: 340px;
  }
  .buy-illustrations {
    gap: 20px;
    justify-content: center;
  }
  .buy-now-details > h3 {
    font-size: 28px;
  }
  .multi-chain-illustration img {
    width: 350px;
  }
}
