.hero-page-container {
  margin-top: 100px;
}

.hero-page-first-details {
  width: 600px;
  height: 200px;
  margin-left: 5%;
  background: url("../../Assets/images/heropage-text-background.png");
  padding: 30px;
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  margin-bottom: -90px;
}

.hero-page-first-details > h5 {
  color: #4cff27;
  font-size: 25px;
  font-weight: 600;
  margin-top: 10px;
  font-family: "Palanquin Dark", sans-serif;
  margin-left: 30px;
  margin-bottom: 40px;
}

.hero-page-second-details {
  padding: 50px;
  position: relative;
  height: 100%;
}

.hero-page-main-details {
  padding: 10px;
  background: url("../../Assets/images/hero-page-text-main-container.svg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  width: 100%;
}

.hero-page-illustration {
  position: absolute;
  top: -100px;
  right: 10%;
  width: 400px;
  animation: fadeUpDown 5s infinite;
}

@keyframes fadeUpDown {
  0% {
    top: -40px;
    opacity: 1;
  }
  50% {
    top: -60px;
    opacity: 0.9;
  }
  100% {
    top: -40px;
    opacity: 1;
  }
}

.hero-page-main-text {
  margin-top: 2%;
  margin-left: 10%;
}

.hero-page-main-text > span {
  color: #4cff27;
  font-family: "Darker Grotesque", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.hero-page-main-text > h4 {
  background: linear-gradient(
    330deg,
    hsl(110deg 100% 58%) 0%,
    hsl(109deg 100% 64%) 39%,
    hsl(107deg 100% 68%) 58%,
    hsl(106deg 100% 72%) 69%,
    hsl(105deg 100% 76%) 77%,
    hsl(104deg 100% 80%) 83%,
    hsl(103deg 100% 83%) 87%,
    hsl(102deg 100% 86%) 91%,
    hsl(101deg 100% 90%) 94%,
    hsl(101deg 100% 93%) 96%,
    hsl(100deg 100% 97%) 98%,
    hsl(0deg 0% 100%) 100%
  );
  font-size: 30px;
  margin-top: 6px;
  margin-bottom: 15px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: 700;
  font-family: "Sora", sans-serif;
}

.hero-page-main-text > h5 > span {
  color: #4cff27;
  font-family: "Darker Grotesque", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.hero-page-main-text > h5 {
  color: #fff;
  font-family: "Darker Grotesque", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.hero-page-buttons {
  display: flex;
  gap: 20px;
  margin-top: 23px;
  margin-bottom: 10px;
}

.hero-page-button div {
  color: #fff;
  background: linear-gradient(
    330deg,
    hsl(110deg 100% 58%) 0%,
    hsl(109deg 100% 64%) 39%,
    hsl(107deg 100% 68%) 58%,
    hsl(106deg 100% 72%) 69%,
    hsl(105deg 100% 76%) 77%,
    hsl(104deg 100% 80%) 83%,
    hsl(103deg 100% 83%) 87%,
    hsl(102deg 100% 86%) 91%,
    hsl(101deg 100% 90%) 94%,
    hsl(101deg 100% 93%) 96%,
    hsl(100deg 100% 97%) 98%,
    hsl(0deg 0% 100%) 100%
  );
  padding: 0.4px 0.4px;
  border: 0.5px solid transparent;
  clip-path: polygon(
    20px 0%,
    100% 0%,
    100% calc(100% - 10px),
    calc(100% - 20px) 100%,
    0% 100%,
    0% 10px
  );
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.hero-page-button div button {
  color: #fff;
  list-style: none;
  font-size: 18px;
  font-family: "Palanquin Dark", sans-serif;
  background-color: #2f392d;
  padding: 9px 35px;
  border: 0.5px solid transparent;
  border-image: linear-gradient(
    330deg,
    hsl(110deg 100% 58%) 0%,
    hsl(109deg 100% 64%) 39%,
    hsl(107deg 100% 68%) 58%,
    hsl(106deg 100% 72%) 69%,
    hsl(105deg 100% 76%) 77%,
    hsl(104deg 100% 80%) 83%,
    hsl(103deg 100% 83%) 87%,
    hsl(102deg 100% 86%) 91%,
    hsl(101deg 100% 90%) 94%,
    hsl(101deg 100% 93%) 96%,
    hsl(100deg 100% 97%) 98%,
    hsl(0deg 0% 100%) 100%
  );
  border-image-slice: 1;
  clip-path: polygon(
    20px 0%,
    100% 0%,
    100% calc(100% - 10px),
    calc(100% - 20px) 100%,
    0% 100%,
    0% 10px
  );
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.hero-page-button-primary button {
  padding: 9px 35px;
  background: #4cff27;
  font-size: 18px;
  font-family: "Palanquin Dark", sans-serif;
  color: #000;
  clip-path: polygon(
    20px 0%,
    100% 0%,
    100% calc(100% - 10px),
    calc(100% - 20px) 100%,
    0% 100%,
    0% 10px
  );
  border: none;
}

.hero-page-button-primary button:hover {
  cursor: pointer;
  background: #fff;
}

.hero-page-button button:hover {
  cursor: pointer;
  background: #fff;
  color: #000;
}

@media (min-width: 1400px) {
  .hero-page-main-text > h4 {
    font-size: 50px;
  }
  .hero-page-main-text {
    margin-top: 2%;
  }
}

@media (min-width: 1700px) {
  .hero-page-illustration {
    width: 550px;
  }
  .hero-page-main-details {
    height: 600px;
  }
}

@media (max-width: 1200px) {
  .hero-page-main-details {
    background-size: cover;
  }
}

@media (max-width: 900px) {
  .hero-page-illustration {
    width: 300px;
  }
  .hero-page-first-details {
    width: 250px;
    height: 100px;
    margin: -60px auto -40px auto;
  }
  .hero-page-first-details > h5 {
    font-size: 12px;
    margin-top: 0px;
    margin-left: 10px;
  }
  .hero-page-main-text {
    margin-left: 0px;
  }
  .hero-page-main-text > h4 {
    font-size: 30px;
  }
}

@media (max-width: 700px) {
  .hero-page-main-text {
    margin-left: 0px;
  }
  .hero-page-second-details {
    padding: 20px;
  }
  .hero-page-main-text > span {
    margin-left: 20px;
    font-size: 18px;
  }
  .hero-page-main-text > h4 {
    font-size: 25px;
    margin-top: 15px;
    margin-left: 20px;
  }
  .hero-page-main-text > h5 {
    font-size: 17px;
    margin-top: 15px;
    margin-left: 20px;
  }
  .hero-page-button div button,
  .hero-page-button-primary button {
    padding: 9px 14px;
    font-size: 16px;
  }
  .hero-page-buttons {
    margin-top: 30px;
    margin-left: 20px;
  }
  .hero-page-main-details {
    background: url("../../Assets/images/heropage-mobile.png");
    height: 650px;
    background-repeat: no-repeat;
    width: 90%;
    background-size: contain;
    margin: -20px auto 0px auto;
    display: block;
    position: relative;
  }
  .hero-page-illustration {
    width: 300px;
    bottom: 0px !important;
    margin-top: 100%;
    left: 10%;
    right: 10%;
  }
}
