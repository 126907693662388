.news-letter-container {
  padding: 20px;
}

.news-letter-details {
  margin-top: 50px;
  padding: 50px;
}

.news-letter-text-socials {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 40px;
}

.news-letter-social-text {
  width: 40%;
}

.news-letter-social-text > h4 {
  color: #fff;
  font-family: "Sora", sans-serif;
  font-size: 18px;
}

.news-letter-social-text > h4 > span {
  color: #4cff27;
}

.news-letter-socials {
  display: flex;
  gap: 30px;
}

.news-letter-socials div > img {
  width: 40px;
}

.news-letter-text {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.news-letter-text > h4 {
  position: absolute;
  margin-top: 13px;
  font-family: "Sora", sans-serif;
  font-size: 23px;
  color: #fff;
  font-weight: 700;
}

.news-letter-text > img {
  position: relative;
  width: 260px;
}

.youtube-iframe {
  display: flex;
  justify-content: center;
  align-items: center;
}

.youtube-iframe iframe {
  width: 760px;
  height: 415px;
}

@media (max-width: 600px) {
  .news-letter-text-socials {
    width: 100%;
    flex-direction: column;
    gap: 30px;
  }
  .news-letter-details {
    padding: 20px;
    margin-top: 20px;
  }
  .news-letter-social-text {
    width: 100%;
  }
  .youtube-iframe iframe {
    width: 600px;
    height: 300px;
  }
}
