@import url("https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
* {
  padding: 0;
  margin: 0;
  /* outline: red solid 1px; */
}

body {
  background: #181821;
  overflow-x: hidden;
}

.sticky-wrapper {
  position: relative;
}
