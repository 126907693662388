.navbar-container {
    padding: 20px;
    transition: ease 0.5s;
}

.navbar-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.sticky {
    background: #181821;
    position: fixed;
    width: 100%;
    z-index: 10;
    height: 100px;
    top: 0;
}

.nav-links ul {
    display: flex;
    align-items: center;
    gap: 50px;
}

.nav-links ul li span {
    position: relative;
    font-family: "Palanquin Dark", sans-serif;
    z-index: 1;
}

.nav-links ul li span>a {
    color: #fff;
    font-family: "Palanquin Dark", sans-serif;
    text-decoration: none;
}

.nav-links ul li {
    color: #fff;
    list-style: none;
    background: linear-gradient( 330deg, hsl(110deg 100% 58%) 0%, hsl(109deg 100% 64%) 39%, hsl(107deg 100% 68%) 58%, hsl(106deg 100% 72%) 69%, hsl(105deg 100% 76%) 77%, hsl(104deg 100% 80%) 83%, hsl(103deg 100% 83%) 87%, hsl(102deg 100% 86%) 91%, hsl(101deg 100% 90%) 94%, hsl(101deg 100% 93%) 96%, hsl(100deg 100% 97%) 98%, hsl(0deg 0% 100%) 100%);
    padding: 0.2px 0.2px;
    border: 0.5px solid transparent;
    clip-path: polygon( 20px 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 20px) 100%, 0% 100%, 0% 10px);
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.nav-links ul li div {
    color: #fff;
    list-style: none;
    background-color: #181821;
    padding: 9px 30px;
    border: 0.5px solid transparent;
    border-image: linear-gradient( 330deg, hsl(110deg 100% 58%) 0%, hsl(109deg 100% 64%) 39%, hsl(107deg 100% 68%) 58%, hsl(106deg 100% 72%) 69%, hsl(105deg 100% 76%) 77%, hsl(104deg 100% 80%) 83%, hsl(103deg 100% 83%) 87%, hsl(102deg 100% 86%) 91%, hsl(101deg 100% 90%) 94%, hsl(101deg 100% 93%) 96%, hsl(100deg 100% 97%) 98%, hsl(0deg 0% 100%) 100%);
    border-image-slice: 1;
    clip-path: polygon( 20px 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 20px) 100%, 0% 100%, 0% 10px);
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.nav-links ul li div:hover {
    background: #fff;
    color: #000;
}

.nav-links ul li:hover {
    cursor: pointer;
}

.nav-links ul li a {
    text-decoration: none;
}

.nav-button button {
    padding: 10px 35px;
    background: #4cff27;
    font-size: 18px;
    font-family: "Palanquin Dark", sans-serif;
    color: #000;
    clip-path: polygon( 20px 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 20px) 100%, 0% 100%, 0% 10px);
    border: none;
}

.nav-button button:hover {
    cursor: pointer;
    background: #fff;
}

.logo a img {
    width: 140px;
}

.toggler {
    color: #fff;
    font-size: 23px;
}

.mobile-nav-list ul li {
    list-style: none;
    margin-bottom: 40px;
    font-size: 18px;
    font-family: "Palanquin Dark", sans-serif;
}

.mobile-nav-list ul li a {
    color: #4cff27;
    text-decoration: none;
}

.ant-drawer .ant-drawer-content {
    background: #181821;
    color: #fff;
}

.toggler:hover {
    cursor: pointer;
}

.ant-drawer-header-title>button {
    color: #4cff27 !important;
}

.mobile-nav-button button {
    padding: 10px 35px;
    background: #4cff27;
    font-size: 18px;
    font-family: "Palanquin Dark", sans-serif;
    color: #000;
    clip-path: polygon( 20px 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 20px) 100%, 0% 100%, 0% 10px);
    border: none;
}

.mobile-nav-button button:hover {
    cursor: pointer;
}

@media (max-width: 1200px) {
    .nav-links ul,
    .nav-button {
        display: none;
    }
    .logo a img {
      width: 120px;
  }
}

@media (min-width: 1200px) {
    .mobile-toggle {
        display: none;
    }
}