.how-to-buy-container {
    padding: 20px;
    background: url("../../Assets/images/why-gigatalik-background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.how-to-buy-sub-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.contract-address {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
    width: 650px;
    padding: 12px;
    font-size: 16px;
    font-family: "Palanquin Dark", sans-serif;
    color: #fff;
    background: #030403;
    border: 1px solid #4cff27;
    border-radius: 15px;
}

.contract-address>input {
    width: 550px;
    font-size: 16px;
    font-family: "Palanquin Dark", sans-serif;
    color: #fff;
    background: #030403;
    border: none;
    border-radius: 15px;
    position: relative;
}

.contract-address .copy-icon {
    color: #4cff27;
    font-size: 25px;
}

.contract-address .copy-icon:hover {
    cursor: pointer;
}

.contract-address>input:focus {
    outline: none;
}

.how-to-buy-buttons {
    display: flex;
    gap: 30px;
}

.hero-page-button-secondary {
    color: #fff;
    list-style: none;
    background: linear-gradient( 330deg, hsl(110deg 100% 58%) 0%, hsl(109deg 100% 64%) 39%, hsl(107deg 100% 68%) 58%, hsl(106deg 100% 72%) 69%, hsl(105deg 100% 76%) 77%, hsl(104deg 100% 80%) 83%, hsl(103deg 100% 83%) 87%, hsl(102deg 100% 86%) 91%, hsl(101deg 100% 90%) 94%, hsl(101deg 100% 93%) 96%, hsl(100deg 100% 97%) 98%, hsl(0deg 0% 100%) 100%);
    padding: 0.2px 0.2px;
    border: 0.5px solid transparent;
    clip-path: polygon( 20px 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 20px) 100%, 0% 100%, 0% 10px);
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.hero-page-button-secondary button {
    color: #fff;
    list-style: none;
    font-family: "Palanquin Dark", sans-serif;
    font-size: 18px;
    font-weight: 500;
    background-color: #9e00ff;
    padding: 9px 30px;
    border: 0.5px solid transparent;
    border-image: linear-gradient( 330deg, hsl(110deg 100% 58%) 0%, hsl(109deg 100% 64%) 39%, hsl(107deg 100% 68%) 58%, hsl(106deg 100% 72%) 69%, hsl(105deg 100% 76%) 77%, hsl(104deg 100% 80%) 83%, hsl(103deg 100% 83%) 87%, hsl(102deg 100% 86%) 91%, hsl(101deg 100% 90%) 94%, hsl(101deg 100% 93%) 96%, hsl(100deg 100% 97%) 98%, hsl(0deg 0% 100%) 100%);
    border-image-slice: 1;
    clip-path: polygon( 20px 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 20px) 100%, 0% 100%, 0% 10px);
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.hero-page-button-secondary button:hover {
    cursor: pointer;
    background: #000;
    color: #fff;
}

.why-gigatalik-details {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.why-gigatalik-main-text {
    display: flex;
    gap: 40px;
}

.why-gigatalik-text>h4 {
    color: #4cff27;
    font-size: 30px;
    font-family: "Sora", sans-serif;
    margin-bottom: 30px;
}

.why-gigatalik-text>h5 {
    color: #fff;
    width: 500px;
    font-size: 20px;
    font-family: "Darker Grotesque";
    font-weight: 700;
}

.why-gigatalik-text>h5>span {
    color: #4cff27;
    font-size: 25px;
}

.why-gigatalik-mini-illustration>img {
    width: 50px;
}

.why-gigatalik-illustration img {
    height: 400px;
}

@media (max-width: 600px) {
    .how-to-buy-container {
        height: auto;
    }
    .contract-address {
        width: 360px;
    }
    .contract-address>input {
        font-size: 19px;
        width: 150px;
    }
    .why-gigatalik-text>h5 {
        width: 340px;
    }
    .why-gigatalik-mini-illustration {
        display: none;
    }
    .copy-icon {
        font-size: 19px !important;
    }
    .why-gigatalik-illustration>img {
        width: 340px;
        margin: 20px auto;
    }
    .why-gigatalik-main-text {
        margin-top: 0px;
    }
    .hero-page-button-secondary button {
        padding: 9px 14px;
        font-size: 16px;
    }
}