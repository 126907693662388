.preloader {
  background: #181821;
  width: 100%;
  height: 100vh;
}

.preloader-details {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.preloader-details > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .preloader-details > div {
  margin-top: 25%;
} */

.preloader-details > div > img {
  width: 200px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
